import React from 'react'
import { Heading } from '@chakra-ui/react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const AnfahrtPage = () => (
  <Layout>
    <SEO title="Anfahrt" />
    <Heading>Anfahrt</Heading>
    <iframe
      title="Anfahrt"
      width="500"
      height="500"
      frameBorder="0"
      scrolling="no"
      marginHeight={0}
      marginWidth={0}
      src="https://maps.google.de/maps?f=q&amp;source=s_q&amp;hl=de&amp;geocode=&amp;q=Thieler+Weg+1+34454+Bad+Arolsen&amp;sll=51.151786,10.415039&amp;sspn=11.556741,39.506836&amp;ie=UTF8&amp;hq=&amp;hnear=Thieler+Weg+1,+Mengeringhausen+34454+Bad+Arolsen,+Waldeck-Frankenberg,+Hessen&amp;ll=51.37204,8.98943&amp;spn=0.719157,2.469177&amp;z=9&amp;output=embed"
    ></iframe>
    <br />
    <small>
      <a
        href="https://maps.google.de/maps?f=q&amp;source=embed&amp;hl=de&amp;geocode=&amp;q=Thieler+Weg+1+34454+Bad+Arolsen&amp;sll=51.151786,10.415039&amp;sspn=11.556741,39.506836&amp;ie=UTF8&amp;hq=&amp;hnear=Thieler+Weg+1,+Mengeringhausen+34454+Bad+Arolsen,+Waldeck-Frankenberg,+Hessen&amp;ll=51.37204,8.98943&amp;spn=0.719157,2.469177&amp;z=9"
        target="_blank"
        rel="noreferrer"
      >
        Größere Kartenansicht
      </a>
    </small>
  </Layout>
)

export default AnfahrtPage
